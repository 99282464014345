import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AniLink from '../components/AniLink'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Springkraf" />
    <div className="container">
      <div className="section-200 text-center">
        <h1>404</h1>
        <p>Page not found.</p>
        <AniLink to="/">Back to home</AniLink>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
